import {SurveyComponent} from "../../components/survey/survey_component";
import env from "../../env";
import {expressions} from "../../survey/validators";

const required = env.ENDPOINT !== "local";

// Base function containing shared survey component definition
const createCreditCheckFormBase = (clientUrlForCatalog) =>
  new SurveyComponent({
    title: {
      en: "Authorization",
      "es-MX": "Autorización",
    },
    elements: [
      {
        title: {
          en: "Unique Population Registry Code (CURP)",
          "es-MX": "CURP",
        },
        type: "text",
        name: "custom_text_21",
        isRequired: required,
        validators: [expressions.curp()],
        placeHolder: "HEGG560427MVZRRL04",
      },
      {
        type: "html",
        name: "Consultar CURP",
        html: "<button hidden class='curp-button'>Consultar CURP</button>",
      },
      {
        type: "html",
        name: "Error consulta CURP",
        html: "<p hidden class='sv_q_erbox' id='curp_validation_error'>No hemos podido validar su CURP, por favor inténtelo de nuevo.</p>"
      },
      {
        type: "html",
        name: "Error wrong format CURP",
        html: `<p hidden class='sv_q_erbox' id='curp_format_error'>La CURP consiste de 18 caracteres
          en el siguiente orden: 4 letras y 6 dígitos seguidos de 6 letras y 2 dígitos o 7 letras 
          y 1 dígito</p>`
      },
      {
        title: {
          en: "Name of the applicant (Natural Person or Legal Representative of the Moral Person):",
          "es-MX":
            "Nombre del solicitante (Persona Física o Representante Legal de la Persona Moral):",
        },
        type: "panel",
        innerIndent: 1,
        name: "names_panel",
        elements: [
          // Credit check currently only has an input for name -> first_name
          {
            title: { en: "Names", "es-MX": "Nombres" },
            type: "text",
            name: "first_name",
            isRequired: required,
          },
          {
            title: { en: "Last name", "es-MX": "Apellido paterno" },
            type: "text",
            name: "surname",
            isRequired: required,
          },
          {
            title: { en: "Mother's last name", "es-MX": "Apellido materno" },
            type: "text",
            name: "second_surname",
          },
        ],
      },
      {
        title: { en: "Date of birth", "es-MX": "Fecha de nacimiento*" },
        type: "text",
        name: "date_of_birth",
        isRequired: required,
        inputType: "date",
        validators: [
          {
            type: "expression",
            text: {
              en: "You must be older than 21 and younger than 75 to apply",
              "es-MX":
                "Debes tener más de 21 y menos de 75 años menos para postularte.",
            },
            expression:
              "(age({date_of_birth}) > 21) && (age({date_of_birth}) < 75)",
          },
        ],
      },
      {
        title: {
          en: "Federal entity of birth",
          "es-MX": "Entidad federativa de nacimiento*",
        },
        type: "text",
        name: "custom_text_6",
        isRequired: required,
      },
      {
        title: { en: "Sex", "es-MX": "Sexo" },
        type: "text",
        name: "gender",
        isRequired: required,
      },
      {
        type: "text",
        name: "custom_text_4",
        isRequired: required,
        title: { en: "Tax ID Number (RFC)", "es-MX": "RFC" },
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a correct Tax Id Number (RFC) of 4 letter and 6 digits",
              "es-MX":
                "Por favor ingrese un RFC correcto de 4 letras y 6 dígitos",
            },
            regex: `^[A-Za-z]{4}[0-9]{6}$`,
          },
        ],
        placeHolder: "VECJ880326",
      },
      {
        title: { en: "Homoclave", "es-MX": "Homoclave" },
        type: "text",
        name: "custom_text_5",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "Please enter a correct homoclave of 3 characters",
              "es-MX":
                "Por favor ingrese una homoclave correcta de 3 caracteres",
            },
            regex: `^[0-9A-Za-z]{3}$`,
          },
        ],
        placeHolder: "XXX",
      },
      {
        title: {
          en: "Please upload a photo of the front of your INE",
          "es-MX": "Por favor subir foto del frente de la INE",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_1",
        isRequired: required,
      },
      {
        title: {
          en: "Please upload a photo of the back of your INE",
          "es-MX": "Por favor subir foto del reverso de la INE",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "file_2",
        isRequired: required,
      },
      {
        title: {
          en: "Proof of residency",
          "es-MX": "Comprobante de domicilio",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "picture_1",
        isRequired: required,
      },
      {
        title: { en: "State", "es-MX": "Estado" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_3",
        renderAs: "select2",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/entidadfederativa/entidades-federativa`,
          titleName: "Descripcion",
          valueName: "Descripcion",
          attachOriginalItems: true,
        },
      },
      { type: "text", name: "custom_text_90", visible: false},
      {
        title: {en: "Municipality", "es-MX": "Municipio"},
        type: "dropdown",
        isRequired: required,
        name: "custom_text_2",
        renderAs: "select2",
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/municipio/id-municipio-by-entidadfederativa?IdEntidadFederativa={custom_text_90}`,
          titleName: "Descripcion",
          valueName: "Descripcion",
          attachOriginalItems: true,
        },
      },
      { type: "text", name: "custom_text_91", visible: false},
      {
        type: "dropdown",
        name: "custom_text_11",
        title: { en: "Suburb", "es-MX": "Colonia" },
        renderAs: "select2",
        isRequired: required,
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/colonia/colonias-municipio?idMunicipio={custom_text_91}&idEntidadFederativa={custom_text_90}`,
          titleName: "Descripcion",
          valueName: "Descripcion",
          attachOriginalItems: true,
        },
      },
      { type: "text", name: "custom_text_92", visible: false},
      { type: "text", name: "codigoPostal", visible: false},
      {
        title: {
          en: "Postal Code",
          "es-MX": "Código postal",
        },
        name: "po_box_postal_code",
        isRequired: required,
        type: "text",
        readOnly: true,
      },
      {
        title: { en: "Address", "es-MX": "Domicilio" },
        type: "text",
        name: "home_street",
        isRequired: required,
      },
      {
        title: { en: "Phone number ", "es-MX": "Teléfono " },
        type: "phone",
        name: "phone_number",
        isRequired: required,
        validators: [
          expressions.phone_en("phone_number"),
          expressions.phone_length("phone_number"),
        ],
      },
      {
        html: {
          en: `
AUTHORIZATION TO REQUEST CREDIT REPORTS INDIVIDUALS / MORAL PERSONS
 
Through this channel, I expressly authorize SOLUCION ASEA SA DE CV SFP, hereinafter CREDICAPITAL, to carry out investigations, through its authorized officials, about my credit behavior or that of the Company that I represent in the credit information companies that they deem appropriate. .
 
Likewise, I declare that I know the nature and scope of the information that will be requested, the use that CREDICAPITAL will make of such information and that it may make periodic inquiries about my history or that of the company I represent, consisting that this authorization is It is in force for a period of 3 years counted from its issuance and in any case during the time that the legal relationship is maintained.
 
In the event that the applicant is a legal person, I declare under protest of telling the truth that I am the legal representative of the company mentioned in this authorization; stating that as of the signing date of this authorization the powers of attorney have not been revoked, limited or modified in any way.

I agree and accept that this document remains the property of CREDICAPITAL and / or the Credit Information Society consulted for the purposes of control and compliance with article 28 of the Law to Regulate Credit Information Companies, which states that companies may only Provide information to a user, when he has the express authorization of the client by means of his autograph signature.
            `,
          "es-MX": `
              <strong>AUTORIZACIÓN PARA SOLICITAR REPORTES DE CRÉDITO PERSONAS FÍSICAS / PERSONAS MORALES</strong><br><br>

              <p style="text-align:justify;word-break:keep-all;">Por este conducto autorizo expresamente a SOLUCION ASEA S.A DE C.V S.F.P., en adelante CREDICAPITAL, para que por conducto de sus funcionarios facultados lleve a  cabo investigaciones, sobre mi  comportamiento crediticio o  el  de  la Empresa que represento en las sociedades de información crediticia que estimen conveniente.</p><br><br>

              <p style="text-align:justify;word-break:keep-all;">Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitara, del uso que CREDICAPITAL, hará de tal información y de que esta podrá realizar consultas periódicas sobre mi historial o el de la empresa que represento, consistiendo que esta autorización se encuentre vigente por un periodo de 3 años contados a partir de su expedición y en todo caso durante el tiempo que se mantenga la relación jurídica.</p><br><br>

              <p style="text-align:justify;word-break:keep-all;">El caso de que el solicitante sea una persona moral, declaro bajo protesta de decir la verdad ser representante legal de la empresa mencionada en esta autorización; manifestando que a la fecha de firma de la presente autorización los poderes no me han sido revocados, limitados ni modificados en forma alguna.</p><br><br>

              <p style="text-align:justify;word-break:keep-all;">Estoy de acuerdo y acepto que este documento quede bajo propiedad de CREDICAPITAL y/o Sociedad de Información Crediticia consultada para efectos de control y cumplimiento del artículo 28 de la Ley para Regular a las sociedades de información Crediticia, mismo que señala que las sociedades solo podrán proporcionar información a un usuario, cuando este cuente con la autorización expresa del cliente mediante su firma autógrafa.</p>
            `,
        },
        type: "html",
      },
      {
        title: " ",
        type: "checkbox",
        name: "custom_jsonfield_20",
        isRequired: required,
        choices: [
          {
            value: "Acepto",
            text: {
              en: "I agree",
              "es-MX": "Acepto",
            },
          },
        ],
        validators: [
          {
            type: "expression",
            text: {
              en: "You should accept before continuing",
              "es-MX": "Debes aceptar antes de continuar",
            },
            expression: "{custom_jsonfield_20} contains 'acepto'",
          },
        ],
      },
      {
        title: {
          en: "Date the credit check authorization is signed:",
          "es-MX":
            "Fecha en que se firma la autorización de verificación de crédito:",
        },
        type: "expression",
        name: "custom_text_23",
        expression: `daysFromNow(0, 0, 'es-MX')`,
      },
      {
        type: "signaturepad",
        name: "signature",
        isRequired: required,
        title: {
          en: "Applicants signature ",
          "es-MX": "Firma el solicitante ",
        },
      },
    ],
  });

export const credit_check_form_credicapital_new_client = (clientUrlForCatalog) =>
  createCreditCheckFormBase(clientUrlForCatalog);

export const credit_check_form_credicapital_recurring_client = (clientUrlForCatalog) => 
  createCreditCheckFormBase(clientUrlForCatalog);
